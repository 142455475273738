import React from "react"
import { Container } from "@material-ui/core"

import "./OurServices.scss"
import Services from "./Services/Services"

const OurServices = () => {
  return (
    <div className="our-services" id="our-services">
      <Container>
        <div className="section-title our-services-title">Our Services</div>

        <div className="section-description our-services-description">
          Our dedicated engineers serve clients worldwide with our comprehensive software solutions.
        </div>

        <div className="services-cards">
          <Services />
        </div>

      </Container>
    </div>
  )
}

export default OurServices
