import React from "react"
import { Container } from "@material-ui/core"

import "./OurClients.scss"
import SliderContainer from "../../shared/Slider"
import { OUR_CLIENTS } from "../../../../static/content/ourClients"

const responsiveSettings = [
  {
    breakpoint: 1024,
    settings: {
      slidesToShow: 3,
      slidesToScroll: 3,
    },
  },
  {
    breakpoint: 710,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 2,
      initialSlide: 2,
    },
  },
  {
    breakpoint: 480,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
    },
  },
]

const OurClients = () => (
  <div className="our-clients" id="our-clients">
    <Container>
      <div className="section-title our-clients-title">Our Clients</div>
      <SliderContainer
        isImages
        items={OUR_CLIENTS}
        responsiveSettings={responsiveSettings}
      />
    </Container>
  </div>
)

export default OurClients
