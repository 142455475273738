import { createMuiTheme } from '@material-ui/core/styles';

// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#556cd6',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: '#ff2b26',
    },
    background: {
      default: '#fff',
    },
  },
});

export default theme;

export const colors = {
  palette_primary_rnd: "#70CE88",
  palette_primary_webDev: "#717CFF",
  palette_primary_annotation: "#FFBC4F",
  palette_primary_mobileDev: "#ED93FF",
  palette_primary_qa: "#FA706C",
  palette_primary_cloud: "#4A90E2",
  palette_primary_dark: "#37474F"
};
