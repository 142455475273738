import React from "react"
import ServiceCard from "./ServiceCard"
import "./Services.scss"
import { SERVICES } from "../../../../../static/content/ourServices"

const Services = () => {
  return (
    <div className="services-list">
      {SERVICES.map(service => (
        <ServiceCard service={service} />
      ))}
    </div>
  )
}

export default Services
