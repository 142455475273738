import React from 'react';
import Avatar from "../../../shared/Avatar"

import './TestimonialCard.scss';

const TestimonialCard = ({ owner, description, firstTestimonial = false }) => (
  <div className={"testimonials-card " + (firstTestimonial && "first-testimonials")}>
    <div className="owner">
      <Avatar {...owner} />
    </div>
    <div className="testimonials-description">
      {description}
    </div>
  </div>
);

export default TestimonialCard;
