import { Height } from "@material-ui/icons"
import React, { useState } from "react"
import LearnMore from "../../../shared/LearnMore"
import CustomModal from "../../../shared/Modal/Modal"
import ServiceModal from "../ServiceModal"

const ServiceCard = ({ service }) => {
  const [isServiceOpen, setServiceOpen] = useState(false)

  return (
    <div
      className="service-card card-item"
      onClick={() => setServiceOpen(true)}
    >
      <div className="service-card-header">
        <div
          className="service-card-icon"
          style={{ backgroundColor: service.bgColor }}
        >
          <img src={service.icon} alt="" style={{
            width:"40px",
            height:"40px"
          }} />
        </div>
        <div className="service-card-title">{service.title}</div>
      </div>
      <div className="service-card-content">{service.semiDescription}</div>
      <div className="learn-more">
        <LearnMore text="Learn More" />
        <CustomModal
          className="our-services-modal"
          isOpen={isServiceOpen}
          setOpen={isOpen => setServiceOpen(isOpen)}
        >
          <ServiceModal service={service} onClose={() => setServiceOpen(false)} />
        </CustomModal>
      </div>
    </div>
  )
}

export default ServiceCard
