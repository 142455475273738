import React, { useEffect, useState } from "react"

import "./HomeSection.scss"
import HeaderImage from "./../../../images/home-header-bg.png"
import DoubleDown from "./../../../images/double-down.svg"
import Button from "../../shared/Button"
import { Container } from "@material-ui/core"
import SocialIcons from "../../shared/SocialIcons"
import Header from "../../Header"
import { PAGES } from "../../../constants"

const HomeSection = () => {
  const [hasRan, setHasRan] = useState(false)
  const [screenSize, setScreenSize] = useState({
    height: 0,
    width: 0,
  })

  const updateScreenSize = () => {
    setScreenSize({ width: window?.innerWidth, height: window?.innerHeight })
  }

  useEffect(() => {
    if (!hasRan) {
      setHasRan(true)
      updateScreenSize()
    }
    window.addEventListener("resize", updateScreenSize)
    return () => {
      window.removeEventListener("resize", updateScreenSize)
    }
  }, [screenSize, hasRan, setHasRan])

  return (
    <div
      className="home-header-container"
      style={{
        background: `url(${HeaderImage})`,
        height: (screenSize?.height ?? 1280) + "px",
        backgroundSize: "cover",
      }}
    >
      <div className="shadow-home-page"></div>
      <Header currentPage={PAGES.HOME} siteTitle={`Home`} />
      <Container>
        <div className="success-talk">
          <div className="title">Empowering Operators Worldwide</div>
          <div className="title-description">
            Operating alongside our clients' businesses, addressing their most
            complex,
            <br /> mission-critical issues through innovative solutions that
            improve their performance and
            <br /> create lasting value across enterprises.
          </div>
          <div className="button-container">
            <Button
              label="Our Services"
              theme="secondary"
              hideArrow={true}
              shape="rect"
              onClick={() => {
                document
                  .getElementById("our-services")
                  .scrollIntoView({ behavior: "smooth" })
              }}
            />
          </div>
          <div className="foothill-social-media-links">
            <SocialIcons />
          </div>
        </div>
        <div className="double-down">
          <img
            src={DoubleDown}
            alt=""
            onClick={() => {
              document
                .getElementById("our-services")
                .scrollIntoView({ behavior: "smooth" })
            }}
          />
        </div>
      </Container>
    </div>
  )
}

export default HomeSection
