import resturant365 from "../../src/images/clients/resturants365.png"
import navco from "../../src/images/clients/navco.png"
import dynamicConsulting from "../../src/images/clients/dynamic-consulting.jpg"
import nationalLink from "../../src/images/clients/nationalLink.png"

export const OUR_CLIENTS = [
  { img: resturant365, link: "https://www.restaurant365.com" },
  { img: navco, link: "https://www.navco.com/" },
  { img: dynamicConsulting, link: "https://www.dynamicconsulting.com/" },
  { img: nationalLink, link: "https://nationallinkatm.com/" },
]
