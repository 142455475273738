import { colors } from "../../src/theme"
import RnD from "../../src/images/services/r&d.svg"
import CustomerSupport from "../../src/images/services/annotation.svg"
import Azure from "../../src/images/services/azure.svg"
import MobileDev from "../../src/images/services/mobileDev.svg"
import QA from "../../src/images/services/qa.svg"
import WebDev from "../../src/images/services/webDev.svg"

export const SERVICES = [
  {
    title: "Software R&D",
    icon: RnD,
    semiDescription:
      "By translating your ideas into a concrete plan for creating an end product",
    description:
      "By translating your ideas into a concrete plan for creating an end product, we help you keep up with market trends and gain a competitive edge in your field.",
    bgColor: colors.palette_primary_rnd,
  },
  {
    title: "Web Development",
    icon: WebDev,
    semiDescription:
      "We have the skills and experience to build a website that will show off your business to its best advantage",
    description:
      "We have the skills and experience to build a website that will show off your business to its best advantage. Our comprehensive service covers engineering, graphic design, servers, network security, content development and more. Front end, back end and full stack – we’ve got it all covered. ",
    bgColor: colors.palette_primary_webDev,
  },
  {
    title: "Customer Support",
    icon: CustomerSupport,
    semiDescription:
      "We deliver a range of customer services to assist customers in making cost effective and correct use of a product",
    description:
      "We deliver a range of customer services to assist customers in making cost effective and correct use of a product. It includes assistance in planning, installation, training, troubleshooting, maintenance, upgrading, and disposal of a product.",
    bgColor: colors.palette_primary_annotation,
  },
  {
    title: "Mobile Development",
    icon: MobileDev,
    semiDescription:
      "Our goal is to deliver a high-quality product that works exactly as you intended. Developing mobile apps for B2B and B2C, iOS and Android, hybrid and cross-platform – we’ve got everything covered. ",
    description:
      "Our goal is to deliver a high-quality product that works exactly as you intended. Developing mobile apps for B2B and B2C, iOS and Android, hybrid and cross-platform – we’ve got everything covered. ",
    bgColor: colors.palette_primary_mobileDev,
  },
  {
    title: "Quality Assurance",
    icon: QA,
    semiDescription:
      "As part of the development process, we carry out stringent quality assurance (QA), both automated and manual",
    description:
      "As part of the development process, we carry out stringent quality assurance (QA), both automated and manual, to verify that the end product meets your requirements and expectations. Any outstanding issues that are identified are then resolved, so that you can launch your new product with absolute confidence. ",
    bgColor: colors.palette_primary_qa,
  },
  {
    title: "Microsoft Cloud Services",
    icon: Azure,
    semiDescription:
      "We use the Microsoft cloud services to build, deploy and manage services and applications through the network worldwide",
    description:
      "We use the Microsoft cloud services to build, deploy and manage services and applications through the network worldwide and are managed by the Data-centers of Microsoft.",
    bgColor: colors.palette_primary_cloud,
  },
]
