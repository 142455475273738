import React from "react"
import { Container } from "@material-ui/core"
import Background from "../../../images/testimonials-bg.svg"
import "./Testimonial.scss"
import SliderContainer from "../../shared/Slider"
import { TESTIMONIALS_CARDS } from "../../../../static/content/testimonials/constants"

const responsiveSettings = [
  {
    breakpoint: 2200,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 2,
    },
  },
  {
    breakpoint: 1200,
    settings: {
      slidesToShow: 1.5,
      slidesToScroll: 1.5,
      initialSlide: 1.5,
    },
  },
  {
    breakpoint: 1100,
    settings: {
      slidesToShow: 1.5,
      slidesToScroll: 1.5,
      initialSlide: 1,
    },
  },
  {
    breakpoint: 950,
    settings: {
      slidesToShow: 0.9,
      slidesToScroll: 1,
    },
  },
  {
    breakpoint: 620,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
    },
  },
]

const Testimonials = () => (
  <div className="testimonials-container" id="our-testimonials">
    <Container>
      <div className="section-title testimonials-title">Testimonials</div>
      <div className="section-description testimonials-title-description">
        Here’s what our clients say about our work.
      </div>
      <div className="bg-container">
        <img src={Background} alt="" />
      </div>
      <div className="testimonials-slider">
        <SliderContainer
          items={TESTIMONIALS_CARDS}
          responsiveSettings={responsiveSettings}
        />
      </div>
    </Container>
  </div>
)

export default Testimonials
