import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import OurServices from "../components/Home/OurServices/OurServices"
import OurClients from "../components/Home/OurClients"
import Testimonials from "../components/Home/Testimonial"
import HomeSection from "../components/Home/HomeSection"

const IndexPage = () => (
  <Layout currentPage="home">
    <SEO title="Home" />
    <HomeSection />
    <OurServices />
    <OurClients />
    <Testimonials />
  </Layout>
)

export default IndexPage
