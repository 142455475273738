import React from 'react';
import CloseIcon from "../../shared/CloseIcon"

const ServiceModal = ({ service, onClose }) => (
  <div className="service-modal">
    <CloseIcon onClose={onClose} />
    <div className="modal-header">
      {service.title}
    </div>
    <div className=" service-description">
      {service.description}
    </div>
  </div>
);

export default ServiceModal;
