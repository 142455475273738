import React from "react"
import Slider from "react-slick"
import Arrow from "../../../images/left arrow.png"

import "./Slider.scss"
import ClientItem from "../../Home/OurClients/ClientItem"

const SlickArrowLeft = () => <img src={Arrow} className="slick-arrow" />

const SlickArrowRight = () => (
  <img src={Arrow} className="slick-arrow slick-arrow-right" />
)

const SliderContainer = ({
  items,
  responsiveSettings,
  isImages = false,
  ...settings
}) => {
  const sharedSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: responsiveSettings,
    prevArrow: <React.Fragment />,
  }

  return (
    <div className="slider-container">
      <Slider dots {...sharedSettings} {...settings}>
        {isImages
          ? items.map(Item => (
              <ClientItem>
                <a href={`${Item.link}`} target="_blank">
                  <img src={Item.img} alt="" width={200} />
                </a>
              </ClientItem>
            ))
          : items.map(Item => <Item />)}
      </Slider>
    </div>
  )
}

export default SliderContainer
